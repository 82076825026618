<template>
  <div>
    <v-row
      dense
      class="mt-4"
    >
      <v-col sm="12">
        <v-alert
          icon="mdi-chart-box-outline"
          text
          type="info"
        >
          <strong>
            Este dashboard foi montado com os
            <span style="text-decoration: underline">
              dados das importações mais recentes
            </span>
            efetuadas no sistema. As datas das importações mais recentes podem
            ser visualizadas na tabela
            <span style="text-decoration: underline">
              Últimas bases importadas
            </span>
            exibida abaixo.
          </strong>
        </v-alert>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col sm="4">
        <v-card
          class="ma-0 pa-0"
          height="100%"
        >
          <div class="caption font-weight-light ml-2 pt-2">
            Área de Concessão e Subestações
          </div>
          <v-row>
            <v-col cols="12">
              <area-concessao nome="Área de Concessão" />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col sm="4">
        <v-card
          class="ma-0 pa-0"
          height="100%"
        >
          <div class="caption font-weight-light ml-2 pt-2">
            Últimas bases importadas
          </div>
          <v-row>
            <v-col cols="12">
              <ultimas-bases-importadas nome="Últimas bases importadas" />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col sm="4">
        <v-card
          class="ma-0 pa-0"
          height="100%"
        >
          <div class="caption font-weight-light ml-2 pt-2">
            Idade Média do Ativo
          </div>
          <v-row>
            <v-col cols="12">
              <idade-media-ativo nome="Idade Média do Ativo" />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col sm="4">
        <v-card
          class="ma-0 pa-0"
          height="100%"
        >
          <div class="caption font-weight-light ml-2 pt-2">VOC, DAC, RES</div>
          <v-row>
            <v-col cols="12">
              <voc-dac-res nome="VOC, DAC, RES" />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col sm="4">
        <v-card
          class="ma-0 pa-0"
          height="100%"
        >
          <div class="caption font-weight-light ml-2 pt-2">
            VOC Adições do Período Incremental
          </div>
          <v-row>
            <v-col cols="12">
              <voc-adicoes-periodo-incremental
                nome="VOC Adições do Período Incremental"
              />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col sm="4">
        <v-card
          class="ma-0 pa-0"
          height="100%"
        >
          <div class="caption font-weight-light ml-2 pt-2">% DAC</div>
          <v-row>
            <v-col cols="12">
              <perc-dac nome="% DAC" />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col sm="4">
        <v-card
          class="ma-0 pa-0"
          height="100%"
        >
          <div class="caption font-weight-light ml-2 pt-2">
            QTD BB AIS e BDGD por TUC
          </div>
          <v-row>
            <v-col cols="12">
              <qtd-bb-ais-bdgd nome="QTD BB AIS e BDGD por TUC" />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col sm="4">
        <v-card
          class="ma-0 pa-0"
          height="100%"
        >
          <div class="caption font-weight-light ml-2 pt-2">
            QTD BI AIS e BDGD por TUC
          </div>
          <v-row>
            <v-col cols="12">
              <qtd-bi-ais-bdgd nome="QTD BI AIS e BDGD por TUC" />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col sm="4">
        <v-card
          class="ma-0 pa-0"
          height="100%"
        >
          <div class="caption font-weight-light ml-2 pt-2">
            QTD Consolidado por TUC
          </div>
          <v-row>
            <v-col cols="12">
              <qtd-consolidado nome="QTD Consolidado por TUC" />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-progress-circular
      v-if="loadingBdgdsComparadas"
      size="15"
      indeterminate
      color="rgba(0, 0, 0, 0.87)"
    />
    <v-row
      class="ma-0 pa-0"
      dense
      v-if="bdgdA && bdgdB && entidadesComparadas.length"
    >
      <v-card width="100%">
        <v-toolbar flat>
          <v-toolbar-title>
            BDGD Ordinária {{ bdgdA.data_registro | formatToMonth }} v{{
              bdgdA.versao
            }}
            x BDGD Ordinária {{ bdgdB.data_registro | formatToMonth }} v{{
              bdgdB.versao
            }}
          </v-toolbar-title>
        </v-toolbar>
        <general-progress-bars
          class="mt-11"
          :items="downloadProgressData"
          prefixText="Baixando ..."
        />
        <v-row dense>
          <v-col cols="10">
            <v-btn
              small
              title="Baixar resumo"
              outlined
              @click="baixarResumoDiferencas"
              :loading="loadingBaixarResumoDiferencas"
              style="float: right; margin-top: 20px"
            >
              <v-icon
                small
                color="primary"
              >
                mdi-download
              </v-icon>
            </v-btn>
          </v-col>
          <v-col cols="2">
            <v-text-field
              v-model="buscaEntidade"
              append-icon="mdi-magnify"
              label="Procurar"
              single-line
              hide-details
              style="max-width: 300px"
              class="mb-2 pa-2"
            />
          </v-col>
        </v-row>
        <v-data-table
          dense
          :headers="cabecalhoResumoDiferencas"
          :items="resumoDiferencas"
          :search="buscaEntidade"
          :items-per-page="5"
          class="pa-1"
        >
          <template v-slot:[`item.entidade`]="{ item }">
            {{ item.entidade | toUpperCase }}
          </template>
          <template v-slot:[`item.bdgd_a_num_linhas`]="{ item }">
            <span
              :class="{
                maior: item.bdgd_a_num_linhas / item.bdgd_b_num_linhas > 1,
                menor: item.bdgd_a_num_linhas / item.bdgd_b_num_linhas < 1
              }"
            >
              {{ item.bdgd_a_num_linhas | parseNumberToIntegerBR }}
            </span>
            <v-icon
              small
              v-if="item.bdgd_a_num_linhas / item.bdgd_b_num_linhas > 1"
              :class="{
                maior: item.bdgd_a_num_linhas / item.bdgd_b_num_linhas > 1,
                menor: item.bdgd_a_num_linhas / item.bdgd_b_num_linhas < 1
              }"
            >
              mdi-arrow-up-thin
            </v-icon>
            <v-icon
              small
              v-if="item.bdgd_a_num_linhas / item.bdgd_b_num_linhas < 1"
              :class="{
                maior: item.bdgd_a_num_linhas / item.bdgd_b_num_linhas > 1,
                menor: item.bdgd_a_num_linhas / item.bdgd_b_num_linhas < 1
              }"
            >
              mdi-arrow-down-thin
            </v-icon>
          </template>
          <template v-slot:[`item.bdgd_b_num_linhas`]="{ item }">
            <span
              :class="{
                maior: item.bdgd_b_num_linhas / item.bdgd_a_num_linhas > 1,
                menor: item.bdgd_b_num_linhas / item.bdgd_a_num_linhas < 1
              }"
            >
              {{ item.bdgd_b_num_linhas | parseNumberToIntegerBR }}
            </span>
            <v-icon
              small
              v-if="item.bdgd_b_num_linhas / item.bdgd_a_num_linhas > 1"
              :class="{
                maior: item.bdgd_b_num_linhas / item.bdgd_a_num_linhas > 1,
                menor: item.bdgd_b_num_linhas / item.bdgd_a_num_linhas < 1
              }"
            >
              mdi-arrow-up-thin
            </v-icon>
            <v-icon
              small
              v-if="item.bdgd_b_num_linhas / item.bdgd_a_num_linhas < 1"
              :class="{
                maior: item.bdgd_b_num_linhas / item.bdgd_a_num_linhas > 1,
                menor: item.bdgd_b_num_linhas / item.bdgd_a_num_linhas < 1
              }"
            >
              mdi-arrow-down-thin
            </v-icon>
          </template>
          <template v-slot:[`item.somente_bdgd_a`]="{ item }">
            <a
              v-if="item.somente_bdgd_a"
              class="link"
              title="Baixar resultado"
              @click.prevent="
                () =>
                  baixarLinhasExistemSomenteNaBdgd({
                    somenteNaBdgd: 'A',
                    entidade: item.entidade,
                    bdgdAId: item.bdgd_a_id,
                    bdgdBId: item.bdgd_b_id
                  })
              "
            >
              {{ item.somente_bdgd_a | parseNumberToIntegerBR }}
            </a>
            <span v-else>
              {{ item.somente_bdgd_a | parseNumberToIntegerBR }}
            </span>
          </template>
          <template v-slot:[`item.somente_bdgd_b`]="{ item }">
            <a
              v-if="item.somente_bdgd_b"
              class="link"
              title="Baixar resultado"
              @click.prevent="
                () =>
                  baixarLinhasExistemSomenteNaBdgd({
                    somenteNaBdgd: 'B',
                    entidade: item.entidade,
                    bdgdAId: item.bdgd_a_id,
                    bdgdBId: item.bdgd_b_id
                  })
              "
            >
              {{ item.somente_bdgd_b | parseNumberToIntegerBR }}
            </a>
            <span v-else>
              {{ item.somente_bdgd_b | parseNumberToIntegerBR }}
            </span>
          </template>
          <template
            v-slot:[`item.com_localizacao_geografica_alterada`]="{ item }"
          >
            <a
              v-if="item.com_localizacao_geografica_alterada"
              class="link"
              title="Baixar resultado"
              @click.prevent="
                () =>
                  baixarLinhasComLocalizacaoGeograficaAlterada({
                    entidade: item.entidade,
                    bdgdAId: item.bdgd_a_id,
                    bdgdBId: item.bdgd_b_id
                  })
              "
            >
              {{
                item.com_localizacao_geografica_alterada
                  | parseNumberToIntegerBR
              }}
            </a>
            <span v-else>
              {{
                item.com_localizacao_geografica_alterada
                  | parseNumberToIntegerBR
              }}
            </span>
          </template>
        </v-data-table>
        <v-tabs
          fixed-tabs
          v-model="currentItem"
        >
          <v-tab
            v-for="item in items"
            :key="item"
            :href="`#tab-${item}`"
            class="primary--text"
          >
            {{ item }}
          </v-tab>
          <v-menu
            v-if="more.length"
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                text
                class="primary--text align-self-center mr-4"
                v-bind="attrs"
                v-on="on"
              >
                mais ({{ more.length }})
                <v-icon right> mdi-menu-down </v-icon>
              </v-btn>
            </template>
            <v-list class="grey lighten-3">
              <v-list-item
                v-for="item in more"
                :key="item.id"
                @click="addItem(item)"
              >
                {{ item }}
              </v-list-item>
            </v-list>
          </v-menu>
        </v-tabs>
        <v-tabs-items v-model="currentItem">
          <v-tab-item
            v-for="item in items.concat(more)"
            :key="item"
            :value="'tab-' + item"
          >
            <v-row dense>
              <v-col sm="12">
                <v-alert
                  text
                  type="info"
                  class="ma-4"
                >
                  <strong>
                    Os componentes abaixo exibem no máximo
                    {{ limit | parseNumberToIntegerBR }} linhas, utilize os
                    botões de exportação para obter o resultado completo.
                  </strong>
                </v-alert>
                <v-expansion-panels
                  accordion
                  class="pa-4"
                >
                  <v-expansion-panel
                    v-if="resumoDiferencasPorEntidade[item].somente_bdgd_a"
                  >
                    <v-expansion-panel-header>
                      Existem somente na BDGD
                      {{ bdgdA.data_registro | formatToMonth }}
                      v{{ bdgdA.versao }}:
                      {{
                        resumoDiferencasPorEntidade[item].somente_bdgd_a
                          | parseNumberToIntegerBR
                      }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <existem-somente-na-bdgd
                        somenteNaBdgd="A"
                        :bdgdVersao="bdgdA"
                        :entidade="resumoDiferencasPorEntidade[item].entidade"
                        :entidadesGeograficas="entidadesGeograficas"
                        :bdgdAId="resumoDiferencasPorEntidade[item].bdgd_a_id"
                        :bdgdBId="resumoDiferencasPorEntidade[item].bdgd_b_id"
                        :limit="limit"
                        @baixarResultado="baixarLinhasExistemSomenteNaBdgd"
                      />
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel
                    v-if="resumoDiferencasPorEntidade[item].somente_bdgd_b"
                  >
                    <v-expansion-panel-header>
                      Existem somente na BDGD
                      {{ bdgdB.data_registro | formatToMonth }}
                      v{{ bdgdB.versao }}:
                      {{
                        resumoDiferencasPorEntidade[item].somente_bdgd_b
                          | parseNumberToIntegerBR
                      }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <existem-somente-na-bdgd
                        somenteNaBdgd="B"
                        :bdgdVersao="bdgdB"
                        :entidade="resumoDiferencasPorEntidade[item].entidade"
                        :entidadesGeograficas="entidadesGeograficas"
                        :bdgdAId="resumoDiferencasPorEntidade[item].bdgd_a_id"
                        :bdgdBId="resumoDiferencasPorEntidade[item].bdgd_b_id"
                        :limit="limit"
                        @baixarResultado="baixarLinhasExistemSomenteNaBdgd"
                      />
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel
                    v-if="
                      resumoDiferencasPorEntidade[item]
                        .com_localizacao_geografica_alterada
                    "
                  >
                    <v-expansion-panel-header>
                      Localização Alterada:
                      {{
                        resumoDiferencasPorEntidade[item]
                          .com_localizacao_geografica_alterada
                          | parseNumberToIntegerBR
                      }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <localizacao-alterada
                        :entidade="resumoDiferencasPorEntidade[item].entidade"
                        :bdgdA="bdgdA"
                        :bdgdB="bdgdB"
                        :limit="limit"
                        @baixarResultado="
                          baixarLinhasComLocalizacaoGeograficaAlterada
                        "
                      />
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-row>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import GeneralProgressBars from '@/components/general/GeneralProgressBars';
import VocDacRes from '@/components/general/graficos/dashboard/VocDacRes';
import PercDac from '@/components/general/graficos/dashboard/PercDac';
import IdadeMediaAtivo from '@/components/general/graficos/dashboard/IdadeMediaAtivo';
import VocAdicoesPeriodoIncremental from '@/components/general/graficos/dashboard/VocAdicoesPeriodoIncremental';
import QtdBbAisBdgd from '@/components/general/graficos/dashboard/QtdBbAisBdgd';
import QtdBiAisBdgd from '@/components/general/graficos/dashboard/QtdBiAisBdgd';
import QtdConsolidado from '@/components/general/graficos/dashboard/QtdConsolidado';
import AreaConcessao from '@/components/general/graficos/dashboard/AreaConcessao';
import UltimasBasesImportadas from '@/components/general/graficos/dashboard/UltimasBasesImportadas';
import ExistemSomenteNaBdgd from '@/components/general/graficos/dashboard/ExistemSomenteNaBdgd';
import LocalizacaoAlterada from '@/components/general/graficos/dashboard/LocalizacaoAlterada';
import DiferencasEntreBdgdsService from '@/services/DiferencasEntreBdgdsService';
import EntidadesBdgdService from '@/services/EntidadesBdgdService';
import { formatToMonth } from '@/filters';

export default {
  name: 'DashboardBrr',
  components: {
    VocDacRes,
    PercDac,
    IdadeMediaAtivo,
    VocAdicoesPeriodoIncremental,
    QtdBbAisBdgd,
    QtdBiAisBdgd,
    QtdConsolidado,
    AreaConcessao,
    UltimasBasesImportadas,
    ExistemSomenteNaBdgd,
    LocalizacaoAlterada,
    GeneralProgressBars
  },
  data: () => ({
    bdgdA: null,
    bdgdB: null,
    entidadesComparadas: [],
    resumoDiferencas: [],
    entidadesGeograficas: [],
    loadingBdgdsComparadas: false,
    loadingBaixarResumoDiferencas: false,
    currentItem: null,
    items: [],
    more: [],
    numAbasVisiveis: 15,
    buscaEntidade: null,
    limit: 1000,
    downloadProgressData: {}
  }),
  mounted() {
    this.getBdgdsComparadas();
    this.getEntidadesGeograficas();
  },
  methods: {
    getBdgdsComparadas() {
      this.loadingBdgdsComparadas = true;
      DiferencasEntreBdgdsService.getBdgdsComparadas()
        .then(({ data }) => {
          const { bdgdA, bdgdB, entidadesComparadas, resumoDiferencas } = data;
          this.bdgdA = bdgdA;
          this.bdgdB = bdgdB;
          this.entidadesComparadas = entidadesComparadas;
          this.resumoDiferencas = resumoDiferencas;
          this.items = this.entidadesComparadas.slice(0, this.numAbasVisiveis);
          this.more = this.entidadesComparadas.slice(
            this.numAbasVisiveis,
            this.entidadesComparadas.length
          );
        })
        .catch((err) => {
          console.log('Erro: ', err);
          this.$toast.error('Erro ao buscar as BDGDs comparadas.', '', {
            position: 'topRight'
          });
        })
        .finally(() => (this.loadingBdgdsComparadas = false));
    },
    getEntidadesGeograficas() {
      EntidadesBdgdService.getEntidadesGeograficas()
        .then(({ data }) => {
          this.entidadesGeograficas = data;
        })
        .catch((err) => {
          console.log('Erro: ', err);
          this.$toast.error(
            'Erro ao buscar as entidades geográficas da BDGD.',
            '',
            {
              position: 'topRight'
            }
          );
        });
    },
    baixarResumoDiferencas() {
      const timestamp = dayjs(new Date()).format('YYYYMMDDHHmm');
      let arquivo = `resumo-diferencas-entre-bdgds-${timestamp}.xlsx`;
      this.$toast.info('Preparando seu arquivo para download.', '', {
        position: 'topRight',
        timeout: 2000
      });
      DiferencasEntreBdgdsService.baixarResumoDiferencas(
        this.bdgdA.id,
        this.bdgdB.id
      )
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', arquivo);
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          this.$toast.error(
            'Erro ao baixar o arquivo resumo das diferenças entre as BDGDs.',
            '',
            { position: 'topRight' }
          );
          console.error('Erro: ', error);
        });
    },
    baixarLinhasExistemSomenteNaBdgd(event) {
      const { somenteNaBdgd, entidade, bdgdAId, bdgdBId } = event;
      const timestamp = dayjs(new Date()).format('YYYYMMDDHHmm');
      const id = `${entidade}-${somenteNaBdgd}-${timestamp}`;
      const bdgd = somenteNaBdgd === 'A' ? this.bdgdA : this.bdgdB;
      let arquivo = `${entidade.toLowerCase()}-linhas-existentes-somente-na-`;
      arquivo += `bdgd-${bdgd.data_registro}-v${bdgd.versao}-${timestamp}.xlsx`;
      this.$set(this.downloadProgressData, id, { id });
      this.$toast.info('Preparando seu arquivo para download.', '', {
        position: 'topRight',
        timeout: 2000
      });
      DiferencasEntreBdgdsService.baixarLinhasExistemSomenteNaBdgd(
        somenteNaBdgd,
        entidade,
        bdgdAId,
        bdgdBId,
        {
          timeout: 60 * 60 * 1000,
          onDownloadProgress: (progressEvent) => {
            let progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            let isComputable = progressEvent.lengthComputable;
            this.$set(this.downloadProgressData, id, {
              progress,
              isComputable,
              id
            });
          }
        }
      )
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', arquivo);
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          this.$toast.error(
            'Erro ao baixar o arquivo resultado das diferenças entre as BDGDs.',
            '',
            { position: 'topRight' }
          );
          console.error('Erro: ', error);
        })
        .finally(() => {
          this.$delete(this.downloadProgressData, id);
        });
    },
    baixarLinhasComLocalizacaoGeograficaAlterada(event) {
      const { entidade, bdgdAId, bdgdBId } = event;
      const timestamp = dayjs(new Date()).format('YYYYMMDDHHmm');
      const id = `${entidade}-${timestamp}`;
      const bdgd = this.bdgdA;
      let arquivo = `${entidade.toLowerCase()}-linhas-com-localizacao-geografica-alterada-`;
      arquivo += `bdgd-${bdgd.data_registro}-v${bdgd.versao}-${timestamp}.xlsx`;
      this.$set(this.downloadProgressData, id, { id });
      this.$toast.info('Preparando seu arquivo para download.', '', {
        position: 'topRight',
        timeout: 2000
      });
      DiferencasEntreBdgdsService.baixarLinhasComLocalizacaoGeograficaAlterada(
        entidade,
        bdgdAId,
        bdgdBId,
        {
          timeout: 60 * 60 * 1000,
          onDownloadProgress: (progressEvent) => {
            let progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            let isComputable = progressEvent.lengthComputable;
            this.$set(this.downloadProgressData, id, {
              progress,
              isComputable,
              id
            });
          }
        }
      )
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', arquivo);
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          this.$toast.error(
            'Erro ao baixar o arquivo de linhas com localização geográfica alteradas.',
            '',
            { position: 'topRight' }
          );
          console.error('Erro: ', error);
        })
        .finally(() => {
          this.$delete(this.downloadProgressData, id);
        });
    },
    addItem(item) {
      const removed = this.items.splice(0, 1);
      const itemIndex = this.more.findIndex((el) => el === item);
      this.items.push(...this.more.splice(itemIndex, 1));
      this.more.push(...removed);
      this.$nextTick(() => {
        this.currentItem = 'tab-' + item.toUpperCase();
      });
    }
  },
  computed: {
    cabecalhoResumoDiferencas() {
      if (!(this.bdgdA && this.bdgdB)) return [];
      const mesA = formatToMonth(this.bdgdA.data_registro);
      const mesB = formatToMonth(this.bdgdB.data_registro);
      return [
        { text: 'Entidade', value: 'entidade' },
        {
          text: `Linhas na BDGD ${mesA} v${this.bdgdA.versao}`,
          value: 'bdgd_a_num_linhas',
          align: 'right'
        },
        {
          text: `Linhas na BDGD ${mesB} v${this.bdgdB.versao}`,
          value: 'bdgd_b_num_linhas',
          align: 'right'
        },
        {
          text: `Existem somente na BDGD ${mesA} v${this.bdgdA.versao}`,
          value: 'somente_bdgd_a',
          align: 'right'
        },
        {
          text: `Existem somente na BDGD ${mesB} v${this.bdgdB.versao}`,
          value: 'somente_bdgd_b',
          align: 'right'
        },
        {
          text: 'Localização Alterada',
          value: 'com_localizacao_geografica_alterada',
          align: 'right'
        }
      ];
    },
    resumoDiferencasPorEntidade() {
      const resumoDiferencasPorEntidade = {};
      this.resumoDiferencas.forEach(
        (item) => (resumoDiferencasPorEntidade[item.entidade] = item)
      );
      return resumoDiferencasPorEntidade;
    }
  }
};
</script>

<style scoped>
.caption {
  text-transform: uppercase;
}
.maior {
  color: green;
}
.menor {
  color: red;
}
.link {
  text-decoration: underline;
}
</style>
