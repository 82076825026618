<template>
  <div
    :id="mapaId"
    :style="{ 'min-height': '300px', 'z-index': 0 }"
  />
</template>

<script>
import L from 'leaflet';
import MapboxConfig from '@/config/MapboxConfig';
import mapbox from '@/config/Mapbox';
import browserPrintMixins from '@/mixins/leaflet/browserPrintMixins';

const mapboxConfig = new MapboxConfig(mapbox.access_token, mapbox.style);

export default {
  name: 'ExistemSomenteNaBdgdMapa',
  mixins: [browserPrintMixins],
  props: {
    bdgdVersao: {
      type: Object,
      required: true
    },
    somenteNaBdgd: {
      type: String,
      default: 'A'
    },
    entidade: {
      type: String,
      required: true
    },
    items: {
      type: Array,
      default: () => []
    },
    latitude: {
      type: Number
    },
    longitude: {
      type: Number
    }
  },
  data: () => ({
    map: null
  }),
  computed: {
    letraBdgd() {
      return this.somenteNaBdgd.toLowerCase();
    },
    mapaId() {
      return `mapa-entidade-${this.entidade.toLowerCase()}-linhas-existem-somente-na-bdgd-${
        this.letraBdgd
      }`;
    }
  },
  methods: {
    configuraMapa(zoom) {
      this.map = L.map(this.mapaId, {
        fullscreenControl: true,
        layers: [mapboxConfig.getDefaultLayer()],
        zoom
      });
      L.control.layers(mapboxConfig.getBaseLayers()).addTo(this.map);
      this.createBrowserPrintControl(L, 'Mapa').addTo(this.map);
      this.posicionaMapa();
    },
    posicionaMapa(latitude = null, longitude = null, zoom = 5) {
      if (!(latitude && longitude)) {
        [longitude, latitude] =
          this.$store.getters.getCompanyCapitalCoordinates;
      }
      this.map.setView([latitude, longitude]);
      this.map.setZoom(zoom);
    },
    plotaItemsNoMapa() {
      this.items.forEach((item) => this.plotaItemNoMapa(item));
    },
    plotaItemNoMapa(item) {
      const { cod_id, geojson } = item;
      const layer = L.geoJSON(JSON.parse(geojson), {
        style: () => {
          const color =
            this.somenteNaBdgd === 'A'
              ? this.bdgdVersao.cor ?? '#9C27B0'
              : this.bdgdVersao.cor ?? '#E65100';
          return { color };
        },
        pointToLayer: (feature, latlng) => {
          return L.circleMarker(latlng, {
            radius: 1
          });
        }
      });
      const msg = `<b>COD_ID:</b> ${cod_id}`;
      layer.bindPopup(msg).addTo(this.map);
    }
  },
  watch: {
    items() {
      this.configuraMapa(5);
      this.plotaItemsNoMapa();
    },
    latitude() {
      this.posicionaMapa(this.latitude, this.longitude, 20);
    },
    longitude() {
      this.posicionaMapa(this.latitude, this.longitude, 20);
    }
  }
};
</script>
