<template>
  <div
    class="d-flex justify-center align-center"
    style="height: 300px"
    v-if="loaded"
  >
    <v-progress-circular
      :rotate="360"
      :size="200"
      :width="30"
      :value="dados.perc_dac"
      color="#FCD3A2"
    >
      <h2>{{ dados.perc_dac | parseNumberToFloatBR }}%</h2>
    </v-progress-circular>
  </div>
</template>

<script>
import DashboardService from '@/services/DashboardService';

export default {
  name: 'PercDac',
  props: {
    nome: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loaded: false,
      dados: {
        perc_dac: 0
      }
    };
  },
  mounted() {
    this.getDados();
  },
  methods: {
    getDados() {
      DashboardService.getDados('PercDac')
        .then((response) => {
          this.loaded = true;
          this.dados = response.data;
        })
        .catch((err) => {
          console.log('Erro: ', err);
          this.$toast.error(
            `Erro ao buscar os dados do painel "${this.nome}".`,
            '',
            { position: 'topRight' }
          );
        });
    }
  }
};
</script>
