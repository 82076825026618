<template>
  <v-row dense>
    <v-col
      cols="12"
      v-if="itemClicado"
    >
      <localizacao-alterada-mapa
        :bdgdA="bdgdA"
        :bdgdB="bdgdB"
        :entidade="entidade"
        :item="itemClicado"
      />
    </v-col>
    <v-col cols="10">
      <v-btn
        small
        title="Baixar o resultado"
        outlined
        @click="baixarResultado"
        :loading="loadingBaixarResultado"
        style="float: right; margin-top: 20px"
      >
        <v-icon
          small
          color="primary"
        >
          mdi-download
        </v-icon>
      </v-btn>
    </v-col>
    <v-col cols="2">
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Procurar"
        single-line
        hide-details
        style="max-width: 300px"
        class="mb-2 pa-2"
      />
    </v-col>
    <v-col cols="12">
      <v-data-table
        dense
        :headers="headers"
        :items="items"
        :search="search"
        :loading="loading"
        :items-per-page="5"
        @click:row="(item) => (itemClicado = item)"
        class="pa-1"
      >
        <template v-slot:[`item.bdgd_a_entidade_geojson`]="{}" />
        <template v-slot:[`item.bdgd_b_entidade_geojson`]="{}" />
        <template v-slot:[`item.diferenca_geojson`]="{}" />
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import DiferencasEntreBdgdsService from '@/services/DiferencasEntreBdgdsService';
import LocalizacaoAlteradaMapa from './LocalizacaoAlteradaMapa';

export default {
  name: 'LocalizacaoAlterada',
  components: { LocalizacaoAlteradaMapa },
  props: {
    entidade: {
      type: String,
      required: true
    },
    bdgdA: {
      type: Object,
      required: true
    },
    bdgdB: {
      type: Object,
      required: true
    },
    limit: {
      type: Number,
      default: 1000
    }
  },
  data: () => ({
    search: null,
    loading: false,
    loadingBaixarResultado: false,
    items: [],
    itemClicado: null
  }),
  mounted() {
    this.getLinhasComLocalizacaoGeograficaAlterada();
  },
  computed: {
    headers() {
      if (!this.items.length) return [];
      return Object.keys(this.items[0]).map((col) => ({
        text: col,
        value: col
      }));
    },
    bdgdAId() {
      return this.bdgdA.id;
    },
    bdgdBId() {
      return this.bdgdB.id;
    }
  },
  methods: {
    getLinhasComLocalizacaoGeograficaAlterada() {
      this.loading = true;
      DiferencasEntreBdgdsService.getLinhasComLocalizacaoGeograficaAlterada(
        this.entidade,
        this.bdgdAId,
        this.bdgdBId,
        this.limit
      )
        .then(({ data }) => {
          this.items = data.resultado;
        })
        .catch((err) => {
          console.log('Erro: ', err);
          this.$toast.error(
            `Erro ao buscar as linhas da entidade ${this.entidade} com localizações alteradas entre as BDGDs.`,
            '',
            {
              position: 'topRight'
            }
          );
        })
        .finally(() => (this.loading = false));
    },
    baixarResultado() {
      this.$emit('baixarResultado', {
        entidade: this.entidade,
        bdgdAId: this.bdgdAId,
        bdgdBId: this.bdgdBId
      });
    }
  }
};
</script>
