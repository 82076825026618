<template>
  <div>
    <div
      :id="mapaId"
      :style="{ 'min-height': '300px', 'z-index': 0 }"
    />
  </div>
</template>

<script>
import L from 'leaflet';
import MapboxConfig from '@/config/MapboxConfig';
import mapbox from '@/config/Mapbox';
import browserPrintMixins from '@/mixins/leaflet/browserPrintMixins';
import { formatToMonth, parseNumberToFloatBR } from '@/filters';

const mapboxConfig = new MapboxConfig(mapbox.access_token, mapbox.style);

export default {
  name: 'LocalizacaoAlteradaMapa',
  mixins: [browserPrintMixins],
  props: {
    bdgdA: {
      type: Object,
      required: true
    },
    bdgdB: {
      type: Object,
      required: true
    },
    entidade: {
      type: String,
      required: true
    },
    item: {
      type: Object
    }
  },
  data: () => ({
    map: null,
    layerControl: null,
    layers: {}
  }),
  mounted() {
    this.configuraMapa(5);
    this.plotaItemsNoMapa();
  },
  computed: {
    mapaId() {
      return `mapa-entidade-${this.entidade.toLowerCase()}-localizacao-alterada`;
    }
  },
  methods: {
    configuraMapa(zoom) {
      this.map = L.map(this.mapaId, {
        fullscreenControl: true,
        layers: [mapboxConfig.getDefaultLayer()],
        zoom
      });
      L.control.layers(mapboxConfig.getBaseLayers()).addTo(this.map);
      this.layerControl = L.control.layers(mapboxConfig.getBaseLayers());
      this.layerControl.addTo(this.map);
      this.createBrowserPrintControl(L, 'Mapa').addTo(this.map);
      this.posicionaMapa();
    },
    posicionaMapa(latitude = null, longitude = null, zoom = 5) {
      if (!(latitude && longitude)) {
        [longitude, latitude] =
          this.$store.getters.getCompanyCapitalCoordinates;
      }
      this.map.setView([latitude, longitude]);
      this.map.setZoom(zoom);
    },
    limpaLayers() {
      Object.keys(this.layers).forEach((layerKey) => {
        const layer = this.layers[layerKey];
        this.layerControl.removeLayer(layer);
        this.map.removeLayer(layer);
      });
    },
    plotaItemsNoMapa() {
      this.plotaDiferenca();
      this.plotaEntidadeBdgd('A');
      this.plotaEntidadeBdgd('B');
    },
    plotaEntidadeBdgd(letraBdgd = 'A') {
      const { cod_id, bdgd_a_entidade_geojson, bdgd_b_entidade_geojson } =
        this.item;
      const geojson =
        letraBdgd === 'A' ? bdgd_a_entidade_geojson : bdgd_b_entidade_geojson;

      const layer = L.geoJSON(JSON.parse(geojson), {
        style: () => {
          const color =
            letraBdgd === 'A'
              ? this.bdgdA.cor ?? '#9C27B0'
              : this.bdgdB.cor ?? '#E65100';
          return { color };
        },
        pointToLayer: (feature, latlng) => {
          return L.circleMarker(latlng, {
            radius: 1
          });
        }
      });

      const bdgd = letraBdgd === 'A' ? this.bdgdA : this.bdgdB;
      const bdgdTxt = `${formatToMonth(bdgd.data_registro)} v${bdgd.versao}`;
      const msg = `
<b>COD_ID:</b> ${cod_id}<br/>
<b>BDGD:</b> ${bdgdTxt}
`;
      layer.bindPopup(msg).addTo(this.map);

      this.layers[`bdgd${letraBdgd}`] = layer;
      this.layerControl.addOverlay(layer, `BDGD ${bdgdTxt}`);
    },
    plotaDiferenca() {
      const { cod_id, diferenca_geojson, diferenca_em_metros } = this.item;
      const layer = L.geoJSON(JSON.parse(diferenca_geojson), {
        style: () => {
          const color = '#E65100';
          return { color };
        },
        pointToLayer: (feature, latlng) => {
          return L.circleMarker(latlng, {
            radius: 1
          });
        }
      });

      const msg = `
<b>COD_ID:</b> ${cod_id}<br/>
<b>Diferença em metros:</b> ${parseNumberToFloatBR(diferenca_em_metros)}
`;
      layer.bindPopup(msg).addTo(this.map);

      this.layers['diferencas'] = layer;
      this.layerControl.addOverlay(layer, 'Diferença');
      this.map.fitBounds(layer.getBounds());
    }
  },
  watch: {
    item() {
      this.limpaLayers();
      this.plotaItemsNoMapa();
    }
  }
};
</script>
