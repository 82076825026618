<template>
  <highcharts
    v-if="loaded"
    :options="chartOptions"
    style="height: 300px !important"
  />
</template>

<script>
import Highcharts from 'highcharts';
import DashboardService from '@/services/DashboardService';

export default {
  name: 'VocAdicoesPeriodoIncremental',
  props: {
    nome: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loaded: false,
      dados: {}
    };
  },
  mounted() {
    this.getDados();
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          style: {
            fontFamily: 'muli'
          }
        },
        title: {
          text: ''
        },
        subtitle: {
          text: ''
        },
        xAxis: {
          categories: this.anos
        },
        yAxis: {
          title: {
            text: 'M R$'
          },
          labels: {
            formatter: function () {
              return Highcharts.numberFormat(this.value, 0, ',');
            }
          }
        },
        legend: {
          enabled: false
        },
        series: [
          {
            type: 'column',
            color: '#F7C6BB',
            name: 'Incremento',
            data: this.vocs
          },
          {
            type: 'line',
            color: '#D7CCC8',
            name: 'Acumulado',
            data: this.acumulados,
            marker: {
              lineWidth: 2,
              lineColor: Highcharts.getOptions().colors[3],
              fillColor: 'white'
            }
          }
        ],
        exporting: {
          menuItemDefinitions: {
            viewFullscreen: {
              text: 'Full Screen'
            }
          },
          buttons: {
            contextButton: {
              menuItems: ['viewFullscreen']
            }
          }
        }
      };
    },
    anos() {
      let anos = [];
      this.dados.forEach(({ ano }) => anos.push(ano));
      return anos;
    },
    vocs() {
      let vocs = [];
      this.dados.forEach(({ voc_em_milhoes: voc }) => vocs.push(Number(voc)));
      return vocs;
    },
    acumulados() {
      let acumulados = [];
      var total = 0;

      for (let i = 0; i < this.dados.length; i++) {
        total += Number(this.dados[i].voc_em_milhoes);
        acumulados.push(Number(total.toFixed(2)));
      }

      return acumulados;
    }
  },
  methods: {
    getDados() {
      DashboardService.getDados('VocAdicoesPeriodoIncremental')
        .then((response) => {
          this.loaded = true;
          this.dados = response.data;
        })
        .catch((err) => {
          console.log('Erro: ', err);
          this.$toast.error(
            `Erro ao buscar os dados do painel "${this.nome}".`,
            '',
            { position: 'topRight' }
          );
        });
    }
  }
};
</script>
