<template>
  <highcharts
    v-if="loaded"
    :options="chartOptions"
    style="height: 300px !important"
  />
</template>

<script>
import Highcharts from 'highcharts';
import DashboardService from '@/services/DashboardService';

export default {
  name: 'VocDacRes',
  props: {
    nome: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loaded: false,
      dados: {}
    };
  },
  mounted() {
    this.getDados();
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          type: 'waterfall',
          style: {
            fontFamily: 'muli'
          }
        },
        title: {
          text: ''
        },
        subtitle: {
          text: ''
        },
        xAxis: {
          type: 'category'
        },
        yAxis: {
          title: {
            text: 'M R$'
          },
          labels: {
            formatter: function () {
              return Highcharts.numberFormat(this.value, 0, ',');
            }
          }
        },
        legend: {
          enabled: false
        },
        tooltip: {
          pointFormat: '<b>R$ {point.y:,.2f} M</b> '
        },
        series: [
          {
            borderWidth: 0,
            upColor: '#CACFD2',
            color: '#F1948A',
            data: [
              {
                color: '#F7C6BB',
                name: 'VOC',
                y: Number(this.dados.voc_em_milhoes)
              },
              {
                color: '#FCD3A2',
                name: 'DAC',
                y: Number(this.dados.dac_em_milhoes)
              },
              {
                color: '#F3A795',
                isIntermediateSum: true,
                name: 'RES',
                y: Number(this.dados.res_em_milhoes)
              }
            ],
            dataLabels: {
              enabled: true,
              formatter: function () {
                return Highcharts.numberFormat(this.y, 0, ',');
              },
              color: '#666',
              style: {
                textOutline: false
              }
            },
            pointPadding: 0
          }
        ],
        exporting: {
          menuItemDefinitions: {
            viewFullscreen: {
              text: 'Full Screen'
            }
          },
          buttons: {
            contextButton: {
              menuItems: ['viewFullscreen']
            }
          }
        }
      };
    }
  },
  methods: {
    getDados() {
      DashboardService.getDados('VocDacRes')
        .then((response) => {
          this.loaded = true;
          this.dados = response.data;
        })
        .catch((err) => {
          console.log('Erro: ', err);
          this.$toast.error(
            `Erro ao buscar os dados do painel "${this.nome}".`,
            '',
            { position: 'topRight' }
          );
        });
    }
  }
};
</script>
