import APIService from './APIService';

export default {
  getDados(dashboard) {
    return APIService.apiCall().get(`/dashboard/get-dados/${dashboard}`);
  },
  getDashboards() {
    return APIService.apiCall().get('/dashboard/get-dashboards');
  }
};
