<template>
  <div
    class="d-flex justify-center align-center"
    style="height: 300px"
    v-if="loaded"
  >
    <h1 class="text-age">
      {{ dados.idade_media_em_anos | parseNumberToFloatBR }}
    </h1>
    <h1 class="text-age-more">anos</h1>
  </div>
</template>

<script>
import DashboardService from '@/services/DashboardService';

export default {
  name: 'IdadeMediaAtivo',
  props: {
    nome: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loaded: false,
      dados: {
        idade_media_em_anos: 0
      }
    };
  },
  mounted() {
    this.getDados();
  },
  methods: {
    getDados() {
      DashboardService.getDados('IdadeMediaAtivo')
        .then((response) => {
          this.loaded = true;
          this.dados = response.data;
        })
        .catch((err) => {
          console.log('Erro: ', err);
          this.$toast.error(
            `Erro ao buscar os dados do painel "${this.nome}".`,
            '',
            { position: 'topRight' }
          );
        });
    }
  }
};
</script>

<style scoped>
.text-age {
  font-size: 120px;
  color: #d1c4e9;
}

.text-age-more {
  color: #d7ccc8;
}
</style>
