<template>
  <div>
    <mapa-brasil v-if="mapaBrasil" />
    <div
      v-else
      id="mapa-area-atuacao"
    />
  </div>
</template>

<script>
import L from 'leaflet';
import MapboxConfig from '@/config/MapboxConfig';
import mapbox from '@/config/Mapbox';
import DashboardService from '@/services/DashboardService';
import browserPrintMixins from '@/mixins/leaflet/browserPrintMixins';

const mapboxConfig = new MapboxConfig(mapbox.access_token, mapbox.style);

export default {
  name: 'AreaConcessao',

  mixins: [browserPrintMixins],

  components: {
    MapaBrasil: () => import('@/components/general/graficos/MapaBrasil.vue')
  },

  props: {
    nome: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      map: null,
      dados: {
        arat: null,
        sub: []
      },
      mapaBrasil: false
    };
  },

  mounted() {
    this.configuraMapa(3);
    this.map.setView([-16.6974198, -49.2334694]);
    this.getDados();
  },

  watch: {
    dados() {
      if (!this.exibeMapaBrasil()) {
        this.adicionaAratMapa();
        this.adicionaSubsMapa();
      }
    }
  },

  methods: {
    exibeMapaBrasil() {
      return this.dados.arat === null;
    },

    configuraMapa(zoom) {
      this.map = L.map('mapa-area-atuacao', {
        fullscreenControl: true,
        layers: [mapboxConfig.getDefaultLayer()],
        zoom
      });

      L.control.layers(mapboxConfig.getBaseLayers()).addTo(this.map);
      this.createBrowserPrintControl(L, 'Área de Concessão').addTo(this.map);
      this.criaLegenda();
    },

    criaLegenda() {
      const legend = L.control({ position: 'bottomleft' });

      legend.onAdd = () => {
        const div = L.DomUtil.create('div', 'mapa-info mapa-legend');

        div.innerHTML += `
          <div style="display:flex; align-items: center; margin: 0px">
            <div style="height: 15px; width: 15px; background-color: #2196f3; opacity: 70%; border-radius: 50%;"></div>
            </span>&nbsp;Subestações
          </div>
        `;

        return div;
      };

      return legend.addTo(this.map);
    },

    getDados() {
      DashboardService.getDados('AreaConcessao')
        .then(({ data }) => {
          this.dados = data;
          this.mapaBrasil = this.exibeMapaBrasil();
        })
        .catch((err) => {
          this.mapaBrasil = true;

          console.log('Erro: ', err);
          this.$toast.error(
            `Erro ao buscar os dados do painel "${this.nome}".`,
            '',
            { position: 'topRight' }
          );
        });
    },

    adicionaAratMapa() {
      if (!(this.dados && this.dados.arat)) return;

      const { area_concessao } = this.dados.arat;

      let layer = L.geoJSON(JSON.parse(area_concessao), {
        style: function () {
          return {
            color: '#f66448',
            weight: 1
          };
        }
      });

      layer.addTo(this.map);

      this.map.fitBounds(layer.getBounds());
    },

    adicionaSubsMapa() {
      if (!(this.dados && this.dados.sub)) return;

      this.dados.sub.forEach(({ area, nome, area_m2 }) => {
        const msg = this.msgPopup(nome, area_m2);

        L.geoJSON(JSON.parse(area), {
          style: function () {
            return {
              color: '#2196f3',
              weight: 1
            };
          }
        })
          .bindPopup(msg)
          .addTo(this.map);
      });
    },

    msgPopup(nome, area_m2) {
      let area_br = parseInt(area_m2).toLocaleString('pt-BR');

      let msg = `
        <b>Subestação:</b> ${nome ?? '-'}<br>
        <b>Área:</b> ${area_br} m² 
      `;

      return msg;
    }
  }
};
</script>

<style>
#mapa-area-atuacao {
  min-height: 300px;
  max-height: 300px;
  z-index: 0;
}

#mapa-area-atuacao .mapa-info {
  padding: 6px 8px;
  font:
    14px/16px Arial,
    Helvetica,
    sans-serif;
  background: white;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

#mapa-area-atuacao .mapa-legend {
  line-height: 18px;
  color: #555;
  max-height: 285px !important;
  overflow-y: auto !important;
}
</style>
