<template>
  <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr>
          <th>Base de dados</th>
          <th>Mês dos dados</th>
          <th>N&ordm; de registros</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>BDGD</td>
          <td>
            <span v-if="dados.bdgd.mes_dados && dados.bdgd.bdgd_versao">
              {{ dados.bdgd.mes_dados | formatToMonth }} v{{
                dados.bdgd.bdgd_versao
              }}
            </span>
            <span v-else>-</span>
          </td>
          <td class="text-right">
            {{ dados.bdgd.num_registros | parseNumberToIntegerBR }}
          </td>
        </tr>
        <tr>
          <td>AIS</td>
          <td>
            <span v-if="dados.ais.mes_dados">
              {{ dados.ais.mes_dados | formatToMonth }}
            </span>
            <span v-else>-</span>
          </td>
          <td class="text-right">
            {{ dados.ais.num_registros | parseNumberToIntegerBR }}
          </td>
        </tr>
        <tr>
          <td>Laudos</td>
          <td>
            <span v-if="dados.laudos.mes_dados">
              {{ dados.laudos.mes_dados | formatToMonth }}
            </span>
            <span v-else>-</span>
          </td>
          <td class="text-right">
            {{ dados.laudos.num_registros | parseNumberToIntegerBR }}
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import DashboardService from '@/services/DashboardService';

export default {
  name: 'UltimasBasesImportadas',

  props: {
    nome: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      dados: {
        bdgd: {
          mes_dados: null,
          bdgd_versao: null,
          num_registros: 0
        },
        ais: {
          mes_dados: null,
          num_registros: 0
        },
        laudos: {
          mes_dados: null,
          num_registros: 0
        }
      }
    };
  },

  mounted() {
    this.getDados();
  },

  methods: {
    getDados() {
      DashboardService.getDados('UltimasBasesImportadas')
        .then((response) => (this.dados = response.data))
        .catch((err) => {
          console.log('Erro: ', err);
          this.$toast.error(
            `Erro ao buscar os dados do painel "${this.nome}".`,
            '',
            { position: 'topRight' }
          );
        });
    }
  }
};
</script>
