<template>
  <comparativo-qtd-ais-bdgd :dados="dados" />
</template>

<script>
import DashboardService from '@/services/DashboardService';

export default {
  name: 'QtdConsolidado',
  components: {
    ComparativoQtdAisBdgd: () =>
      import(
        '@/components/general/graficos/dashboard/ComparativoQtdAisBdgd.vue'
      )
  },
  props: {
    nome: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      dados: []
    };
  },
  mounted() {
    this.getDados();
  },
  methods: {
    getDados() {
      DashboardService.getDados('QtdConsolidado')
        .then((response) => (this.dados = response.data))
        .catch((err) => {
          console.log('Erro: ', err);
          this.$toast.error(
            `Erro ao buscar os dados do painel "${this.nome}".`,
            '',
            { position: 'topRight' }
          );
        });
    }
  }
};
</script>
