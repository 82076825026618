import APIService from './APIService';

export default {
  getBdgdsComparadas() {
    return APIService.apiCall().get('/diferencas-entre-bdgds/get-bdgds-comparadas');
  },
  getLinhasExistemSomenteNaBdgd(
    somenteNaBdgd,
    entidade,
    bdgdAId,
    bdgdBId,
    limit = 1000
  ) {
    return APIService.apiCall().get(`/diferencas-entre-bdgds/get-linhas-existem-somente-na-bdgd/${somenteNaBdgd}/${entidade}/${bdgdAId}/${bdgdBId}/${limit}`);
  },
  baixarLinhasExistemSomenteNaBdgd(
    somenteNaBdgd,
    entidade,
    bdgdAId,
    bdgdBId, 
    config = {}
  ) {
    return APIService.apiCall().get(
      `/diferencas-entre-bdgds/baixar-linhas-existem-somente-na-bdgd/${somenteNaBdgd}/${entidade}/${bdgdAId}/${bdgdBId}`,
      { responseType: 'blob', ...config }
    );
  },
  getLinhasComLocalizacaoGeograficaAlterada(
    entidade,
    bdgdAId,
    bdgdBId,
    limit = 1000
  ) {
    return APIService.apiCall().get(`/diferencas-entre-bdgds/get-linhas-com-localizacao-geografica-alterada/${entidade}/${bdgdAId}/${bdgdBId}/${limit}`);
  },
  baixarLinhasComLocalizacaoGeograficaAlterada(
    entidade,
    bdgdAId,
    bdgdBId, 
    config = {}
  ) {
    return APIService.apiCall().get(
      `/diferencas-entre-bdgds/baixar-linhas-com-localizacao-geografica-alterada/${entidade}/${bdgdAId}/${bdgdBId}`,
      { responseType: 'blob', ...config }
    );
  },
  baixarResumoDiferencas(
    bdgdAId,
    bdgdBId, 
    config = {}
  ) {
    return APIService.apiCall().get(
      `/diferencas-entre-bdgds/baixar-resumo-diferencas/${bdgdAId}/${bdgdBId}`,
      { responseType: 'blob', ...config }
    );
  }
};
